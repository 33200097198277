<template>
	<div class="">

		<!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{$t('i18nn_55793d80c64c2dc7')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('i18nn_73195b8b5e85d58a')}}</el-breadcrumb-item>
    </el-breadcrumb> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>{{$t('i18nn_52e1fa389a9ced75')}}</el-breadcrumb-item>
					</el-breadcrumb>
				</el-col>
				<el-col :span="18" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
					<el-button type="success" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button>
					<el-button type="danger" icon="el-icon-delete" size="small" @click="delSelPageAction()">{{$t('e33c9b93c36fd250')}}</el-button>

					<el-button type="warning" icon="el-icon-s-promotion" size="small" @click="runAction()">{{$t('i18nn_762cf41ecbb3ee58')}}</el-button>
					<el-button type="danger" icon="el-icon-video-pause" size="small" @click="closeAction()">{{$t('i18nn_8eb6e92d013f9555')}}</el-button>
					<el-button type="primary" icon="el-icon-video-play" size="small" @click="openAction()">{{$t('i18nn_243a6ecd4e231c9c')}}</el-button>
				</el-col>
			</el-row>
		</div>

		<!-- <el-menu :default-active="$route.path" :router="true" class="" mode="horizontal" @select="handleSelect">
      <el-menu-item :route="{name:'DicConfig'}" index="/Frame/DicConfig">{{$t('i18nn_cbf03affa6d6ca3d')}}</el-menu-item>
      <el-menu-item :route="{name:'ParmConfig'}" index="/Frame/ParmConfig">{{$t('i18nn_2bbc6b12fbdf9019')}}</el-menu-item>
      <el-menu-item :route="{name:'ControlConfig'}" index="/Frame/ControlConfig">{{$t('i18nn_2fb94604c67cb05c')}}</el-menu-item>
      <el-menu-item :route="{name:'MessageConfig'}" index="/Frame/MessageConfig">{{$t('i18nn_b0b291800a22292a')}}</el-menu-item>
    </el-menu> -->

		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
			<!-- <div class="tableConHeader">

      </div> -->
			<div class="tableConTable">
				<el-table :height="$store.state.tableMaxHeight" :data="tableData" stripe border @row-click="handleCurrentChange"
					ref="multipleTable" :highlight-current-row="true" style="width: 100%">
					<el-table-column width="50px">
						<template slot-scope="scope">
							<span class="tran_box">
								<el-checkbox v-model="scope.row.selected"></el-checkbox>
							</span>
						</template>
					</el-table-column>
					<el-table-column type="index" width="50">
					</el-table-column>
					<el-table-column prop="targetSystem" :label="$t('i18nn_8ed9142b991183f8')">
					</el-table-column>
					<el-table-column prop="taskDesc" :label="$t('i18nn_ea4756bc1642e0f1')">
					</el-table-column>
					<el-table-column prop="targetObject" :label="$t('i18nn_70b9676c2dd63579')">
						<template slot-scope="scope">
							<span>{{scope.row.targetObject}}</span>.
							<span>{{scope.row.targetMethod}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="taskCron" :label="$t('i18nn_05ee04f297da360d')">
					</el-table-column>
					<el-table-column prop="nextFireTime" :label="$t('i18nn_043d67a736a50795')">
					</el-table-column>
					<el-table-column prop="previousFireTime" :label="$t('i18nn_eacf3e797aa9647b')">
					</el-table-column>
					<el-table-column prop="enable" :label="$t('6cdece641436d7ab')" :formatter="formatterEnable">
						<template slot-scope="scope">
							<el-tag v-if="'NORMAL'===scope.row.status">{{$t('i18nn_e0199d6ecc3ee81a')}}</el-tag>
							<el-tag v-else-if="'PAUSED'===scope.row.status" type="info">{{$t('i18nn_8eb6e92d013f9555')}}</el-tag>
							<el-tag v-else-if="'COMPLETE'===scope.row.status" type="info">{{$t('25e03120b83ee4ed')}}</el-tag>
							<el-tag v-else-if="'ERROR'===scope.row.status" type="info">{{$t('i18nn_086a73016c53d692')}}</el-tag>
							<el-tag v-else-if="'BLOCKED'===scope.row.status" type="info">{{$t('i18nn_06c0f8db93cf78c4')}}</el-tag>
							<el-tag v-else type="info">{{scope.row.status}}</el-tag>

						</template>
					</el-table-column>
					<el-table-column prop="contactName" :label="$t('i18nn_714305346b0dbfb6')">
					</el-table-column>
				</el-table>
			</div>
			<div class="tableConPagination">
				<hy-page-pagination :pagination='pagination' v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>
		<!-- 修改弹窗 -->
		<el-dialog :title="$t('6267f3aedf895209')" :close-on-click-modal="false" :visible.sync="dialogFormVisible" :top="'10px'" width="500">
			<el-form ref="form" :rules="formRules" :model="form" label-width="120px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">
				<el-form-item label="所属系统：" prop="">
					<el-input type="text" v-model="form.targetSystem" :placeholder="$t('i18nn_b8621f081ba09c7c')"></el-input>
				</el-form-item>
				<el-form-item label="执行对象：" prop="targetObject">
					<el-input type="text" v-model="form.targetObject" :placeholder="$t('i18nn_c15ee49569aa67a3')"></el-input>
				</el-form-item>
				<el-form-item label="执行方法：" prop="targetMethod">
					<el-input type="text" v-model="form.targetMethod" :placeholder="$t('i18nn_fcd432862f71ff63')"></el-input>
				</el-form-item>
				<el-form-item label="执行类型：" prop="jobType">
					<el-radio-group v-model="form.jobType">
						<el-radio :label="'statefulJob'">单行(阻塞)</el-radio>
						<el-radio :label="'job'">并行(非阻塞)</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="执行器类型：" prop="taskType">
					<el-radio-group v-model="form.taskType">
						<el-radio :label="'LOCAL'">本地(LOCAL)</el-radio>
						<el-radio :label="'DUBBO'">远程(DUBBO)</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="执行频率：" prop="taskCron">
					<el-input type="text" v-model="form.taskCron" placeholder="请输入CRON表达式(0 0/5 * * * ?)"></el-input>
				</el-form-item>
				<el-form-item label="备注描述：" :placeholder="$t('i18nn_4eed7667ef911a72')">
					<el-input type="textarea" v-model="form.taskDesc" :maxlength="1000" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="通知给人员：" prop="contactName">
					<!-- <el-select filterable clearable v-model="form.contactName" :placeholder="$t('i18nn_fc2a95852c846bae')" @change="selChange($event)">
						<el-option v-for="item in selectOption.userList" :key="item.id" :label="item.userName" :value="item.id">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ item.userName }}</span>
								<span class="sel_option_code" style="">{{ item.phone }}</span>
							</div>
						</el-option>
					</el-select> -->
					<el-input type="text" v-model="form.contactName" placeholder="请输入隶属人员ID"></el-input>
				</el-form-item>


			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{$t('4e9fc68608c60999')}}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('4ce9979bfb6576d9')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {

		data() {
			return {
				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改
				selectOption: {
					userList: [],
				},
				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				// multipleSelection:[],
				//查询，排序方式
				filterData: {
					"orderBy": "id_", //排序字段
					"sortAsc": "desc", //desc降序，asc升序
				},
				//弹窗
				dialogTableVisible: false,
				loading_load: false,
				form: {
					"targetSystem": "",
					"targetObject": "", //执行对象
					"targetMethod": "", //执行方法
					"jobType": "", //执行类型
					"taskType": "", //任务类型
					"taskCron": "", //定时任务表达式
					"taskDesc": "", // 任务描述
					"contactName": "" // 联系人
				},
				formRules: {
					taskCron: [{
							required: true,
							message: this.$t('i18nn_2ce736eb73976890'),
							trigger: 'blur'
						},
						// { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
					],
					targetMethod: [{
							required: true,
							message: this.$t('i18nn_fcd432862f71ff63'),
							trigger: 'blur'
						},
						// { type: 'number', message: '手机号必须为数字'}
					],
					targetObject: [{
						required: true,
						message: this.$t('i18nn_c15ee49569aa67a3'),
						trigger: 'blur'
					}, ],
					taskDesc: [{
						required: true,
						message: this.$t('i18nn_cbfcb27fae20f059'),
						trigger: 'blur'
					}, ],
					jobType: [{
						required: true,
						message: this.$t('i18nn_5cc8ea0e79e45cb0'),
						trigger: 'blur'
					}, ],
					taskType: [{
						required: true,
						message: this.$t('i18nn_001b535c694bfabd'),
						trigger: 'blur'
					}, ],

				},
				currentSel: {}
			}
		},
		//创建时
		created() {
			this.initData()
		},
		//编译挂载前
		mounted() {
			//初始化省市县
			// this.initLinkageData = {
			//   provinceId: '',
			//   cityId: '',
			//   countyId: '',
			// }
			// console.log("mounted");

		},
		methods: {
			initData() {
				this.getPageData();
				//人员数据
				// this.getProvinceData();
				// this.getUserData("");
			},
			//状态
			formatterEnable(row, column) {
				if ('1' === row.enable) {
					return this.$t('i18nn_889b3ef0590d9f54');
				} else if ('0' === row.enable) {
					return this.$t('4e9fc68608c60999');
				} else {
					return row.enable;
				}
			},
			//选择用户
			handleCurrentChange(val) {
				console.log('handleCurrentChange', val);

				// this.$refs.multipleTable2.clearSelection();
				// this.getUserByRole({userId:val.id});
				// debugger;
				this.tableData.map(item => {
					if (item.taskName === val.taskName) {
						item.selected = true;
					} else {
						item.selected = false;
					}
				});
				this.currentSel = val;
				// this.$refs.multipleTable.toggleRowSelection(this.tableData3[2],true);
			},
			//选择
			// handleSelectionChange(val) {
			//     this.multipleSelection = val;
			//   },
			//打开新增编辑，弹窗
			openDioalog(formParm) {
				console.log(formParm);
				this.dialogFormVisible = true;
				let form = Object.assign({}, formParm);
				console.log(form);
				if (null === formParm) { //新增
					// 重置
					this.resetForm('form');
					this.dialogFormStatus = 0;
				} else { //修改
					// 重置
					this.resetForm('form');
					this.dialogFormStatus = 1;
				}
			},
			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {

					if (valid) {
						let formData = Object.assign({}, this.form);
						// debugger;
						if (0 === this.dialogFormStatus) {
							formData.id = null;
							this.postData(this.$urlConfig.HyScheduledTasks, formData);
						} else {
							this.postData(this.$urlConfig.HyScheduledTasks, formData);
						}

					} else {
						console.log('error submit!!');
						this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				this[formName] = {};
				// console.log(this.$refs[formName]);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// this.form.remark = ""; //  备注//非必填
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},
			//提交信息
			postData(url, formData) {
				let _this = this;
				this.loading = true;

				this.$http.post(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loading = false;
						if (200 == data.code) {
							this.dialogFormVisible = false;
							this.getPageData();
							this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
								type: 'success',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						} else {
							if (!data.msg) {
								data.msg = "提交失败,请重试";
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			},

			//请求分页数据
			getPageData() {
				let _this = this;
				this.loading_load = true;
				this.$http.put(this.$urlConfig.HyScheduledTasksPage, {
						"sortAsc": this.filterData.sortAsc,
						"orderBy": this.filterData.orderBy,
						"offset": (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						"limit": this.pagination.page_size, //当前页显示数目
					})
					.then(({
						data
					}) => {
						console.log("调度配置列表，请求成功");
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}

					})
					.catch((error) => {
						console.log(error);
						console.log("调度配置列表，请求失败");
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},
			//下拉框选择改变
			selChange(vId) {
				console.log(arguments);
				// let obj = this.getGcNameByGcId(this.selectOption['userList'],vId);
				// console.log(obj);
				// this['form']['deptName'] = obj.deptName;
			},
			//根据下拉框id获得对象
			getGcNameByGcId(list, id) {
				// let name="";
				for (let i = list.length - 1; i >= 0; i--) {
					// list[i]
					if (list[i].id === id) {
						return list[i];
					}
				}
				return {
					'subValue': this.$t('i18nn_6b57a5faf337b87b')
				};
			},
			//查询人员接口
			// getUserData(keyword) {
			// 	let _this = this;
			// 	console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http.put(this.$urlConfig.HyAdminUserInfo, {
			// 			keyword: keyword
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log("查询人员接口，请求成功");
			// 			console.log(data);
			// 			if (200 == data.code) {
			// 				this.selectOption.userList = data.rows;
			// 				// this.$alert('恭喜,查询人员接口成功！', this.$t('cc62f4bf31d661e3'), {
			// 				//   type: 'success',
			// 				//   confirmButtonText: this.$t('204ffab8a6e01870'),
			// 				// });
			// 				// this.getPageData();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = "查询人员接口失败,请重试";
			// 				}
			// 				// this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 				//   type: 'warning',
			// 				//   confirmButtonText: this.$t('204ffab8a6e01870'),
			// 				// });
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch((error) => {
			// 			console.log(error);
			// 			console.log("查询人员接口，请求失败");
			// 			// this.loading_load = false;
			// 			// this.$alert('查询人员接口失败,请重试！', this.$t('cc62f4bf31d661e3'), {
			// 			//   type: 'warning',
			// 			//   confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			// });
			// 			this.$message.error('查询人员接口失败,请重试！');
			// 		});
			// },
			//查询详情接口
			// getUserDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.$http.put(this.$urlConfig.HyUserInfoDet, {
			// 			"id": id,
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log("调度配置详情，请求成功");
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				let form = data.data;
			// 				form.enable = form.enable === '1' ? true : false;
			// 				//浅拷贝、对象属性的合并
			// 				this.form = Object.assign({}, form);
			// 				//表格显示数据
			// 				// this.tableData = data.rows;
			// 				//当前数据总条数
			// 				// this.pagination.total = parseInt(data.total);
			// 				//当前页数
			// 				// this.pagination.current_page = parseInt(data.current);
			// 				//当前页条数
			// 				// this.pagination.page_size = parseInt(data.size);
			// 				// this.$message.success('调度配置详情，请求成功');
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_35da7aebbfd476d0'));
			// 			}

			// 		})
			// 		.catch((error) => {
			// 			console.log(error);
			// 			console.log("调度配置详情，请求失败");
			// 			this.$message.error('详情数据，请求失败！');
			// 			this.loading = false;
			// 		});
			// },
			//删除选择操作
			delSelPageAction() {
				this.delPageAction(this.currentSel);
			},

			//删除操作
			delPageAction(dataParm) {
				this.$confirm(this.$t('i18nn_18a47a2171c01d1c') + dataParm.taskDesc + '吗?', this.$t('daaaeb1b7b22b126'), {
					confirmButtonText: this.$t('204ffab8a6e01870'),
					cancelButtonText: this.$t('4b3340f7872b411f'),
					type: 'warning'
				}).then(() => {
					this.delPageData(dataParm);
				}).catch(() => {

				});
			},
			//删除特定数据
			delPageData(dataParm) {
				let _this = this;
				console.log(dataParm);
				this.loading_load = true;
				this.$http.delete(this.$urlConfig.HyScheduledDel, {
						data: {
							taskGroup: dataParm.taskGroup,
							taskName: dataParm.taskName
						}
					})
					.then(({
						data
					}) => {
						console.log("删除，请求成功");
						console.log(data);
						this.loading_load = false;
						if (200 == data.code) {
							this.$alert('恭喜,删除成功！', this.$t('cc62f4bf31d661e3'), {
								type: 'success',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
							this.getPageData();
						} else {
							if (!data.msg) {
								data.msg = "删除失败,请重试";
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log("删除，请求失败");
						this.loading_load = false;
						this.$alert('删除失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			},

			//执行
			runAction() {
				let msg = this.$t('i18nn_762cf41ecbb3ee58');
				let currentSel = this.currentSel;
				this.$confirm(msg + currentSel.targetMethod + '吗?', this.$t('daaaeb1b7b22b126'), {
					confirmButtonText: this.$t('204ffab8a6e01870'),
					cancelButtonText: this.$t('4b3340f7872b411f'),
					type: 'warning'
				}).then(() => {
					this.updatePageData(this.$urlConfig.HyScheduledTasksRun, {
						taskGroup: currentSel.taskGroup,
						taskName: currentSel.taskName
					}, msg);
				}).catch(() => {

				});
			},
			//暂停
			closeAction() {
				let msg = this.$t('i18nn_8eb6e92d013f9555');
				let currentSel = this.currentSel;
				this.$confirm(msg + currentSel.targetMethod + '吗?', this.$t('daaaeb1b7b22b126'), {
					confirmButtonText: this.$t('204ffab8a6e01870'),
					cancelButtonText: this.$t('4b3340f7872b411f'),
					type: 'warning'
				}).then(() => {
					this.updatePageData(this.$urlConfig.HyScheduledTasksClose, {
						taskGroup: currentSel.taskGroup,
						taskName: currentSel.taskName
					}, msg);
				}).catch(() => {

				});
			},
			//启动
			openAction() {
				let msg = this.$t('i18nn_243a6ecd4e231c9c');
				let currentSel = this.currentSel;
				this.$confirm(msg + currentSel.targetMethod + '吗?', this.$t('daaaeb1b7b22b126'), {
					confirmButtonText: this.$t('204ffab8a6e01870'),
					cancelButtonText: this.$t('4b3340f7872b411f'),
					type: 'warning'
				}).then(() => {
					this.updatePageData(this.$urlConfig.HyScheduledTasksOpen, {
						taskGroup: currentSel.taskGroup,
						taskName: currentSel.taskName
					}, msg);
				}).catch(() => {

				});
			},

			//更新特定数据, 设为默认地址
			updatePageData(url, dataParm, msg) {
				let _this = this;
				this.loading = true;
				//浅拷贝、对象属性的合并
				let dataParmAss = Object.assign({}, dataParm);
				// dataParmAss.isDefault = 1;
				this.$http.post(url, dataParmAss)
					.then(({
						data
					}) => {
						this.loading = false;
						console.log(msg + "，请求成功");
						console.log(data);
						this.$alert(msg + '成功！', this.$t('cc62f4bf31d661e3'), {
							type: 'success',
							confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						this.getPageData();

					})
					.catch((error) => {
						console.log(error);
						console.log(msg + "，请求失败");
						this.loading = false;
						this.$alert(msg + ',请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			}

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>